.webui-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1800;
  display: none;
  width: 276px;
  min-height: 32px;
  padding: 0;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e0e0;
  border: 1px solid rgba(189, 189, 189, 0.8);
  border-radius: 0.286rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); }
  .webui-popover.top, .webui-popover.top-left, .webui-popover.top-right {
    margin-top: -8px; }
  .webui-popover.right, .webui-popover.right-top, .webui-popover.right-bottom {
    margin-left: 8px; }
  .webui-popover.bottom, .webui-popover.bottom-left, .webui-popover.bottom-right {
    margin-top: 8px; }
  .webui-popover.left, .webui-popover.left-top, .webui-popover.left-bottom {
    margin-left: -8px; }
  .webui-popover.pop {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    transition: -webkit-transform 0.15s cubic-bezier(0.3, 0, 0, 1.5);
    transition: transform 0.15s cubic-bezier(0.3, 0, 0, 1.5);
    transition: transform 0.15s cubic-bezier(0.3, 0, 0, 1.5), -webkit-transform 0.15s cubic-bezier(0.3, 0, 0, 1.5); }
  .webui-popover.fade {
    transition: opacity .15s linear; }
  .webui-popover.in {
    -webkit-transform: none;
            transform: none;
    opacity: 1; }

.webui-popover-inner .close {
  float: right;
  margin: 5px 10px 0 0;
  font-family: arial;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  opacity: .2; }
  .webui-popover-inner .close:hover, .webui-popover-inner .close:focus {
    opacity: .5; }

.webui-popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 1rem;
  background-color: #eee;
  border-bottom: 1px solid #e1e1e1;
  border-radius: -0.714rem -0.714rem 0 0; }

.webui-popover-content {
  padding: 20px;
  overflow: auto; }
  .webui-popover-content > *:last-child {
    margin-bottom: 0; }

.webui-no-padding .webui-popover-content {
  padding: 0; }

.webui-no-padding .list-group-item {
  border-right: none;
  border-left: none; }
  .webui-no-padding .list-group-item:first-child {
    border-top: 0; }
  .webui-no-padding .list-group-item:last-child {
    border-bottom: 0; }

.webui-popover > .webui-arrow, .webui-popover > .webui-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.webui-popover > .webui-arrow {
  border-width: 9px; }

.webui-popover > .webui-arrow:after {
  content: "";
  border-width: 8px; }

.webui-popover.top > .webui-arrow,
.webui-popover.top-right > .webui-arrow,
.webui-popover.top-left > .webui-arrow {
  bottom: -9px;
  left: 50%;
  margin-left: -9px;
  border-top-color: #adadad;
  border-top-color: rgba(189, 189, 189, 0.8);
  border-bottom-width: 0; }
  .webui-popover.top > .webui-arrow:after,
  .webui-popover.top-right > .webui-arrow:after,
  .webui-popover.top-left > .webui-arrow:after {
    bottom: 1px;
    margin-left: -8px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0; }

.webui-popover.right > .webui-arrow,
.webui-popover.right-top > .webui-arrow,
.webui-popover.right-bottom > .webui-arrow {
  top: 50%;
  left: -9px;
  margin-top: -9px;
  border-right-color: #adadad;
  border-right-color: rgba(189, 189, 189, 0.8);
  border-left-width: 0; }
  .webui-popover.right > .webui-arrow:after,
  .webui-popover.right-top > .webui-arrow:after,
  .webui-popover.right-bottom > .webui-arrow:after {
    bottom: -8px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0; }

.webui-popover.bottom > .webui-arrow,
.webui-popover.bottom-right > .webui-arrow,
.webui-popover.bottom-left > .webui-arrow {
  top: -9px;
  left: 50%;
  margin-left: -9px;
  border-top-width: 0;
  border-bottom-color: #adadad;
  border-bottom-color: rgba(189, 189, 189, 0.8); }
  .webui-popover.bottom > .webui-arrow:after,
  .webui-popover.bottom-right > .webui-arrow:after,
  .webui-popover.bottom-left > .webui-arrow:after {
    top: 1px;
    margin-left: -8px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff; }

.webui-popover.left > .webui-arrow,
.webui-popover.left-top > .webui-arrow,
.webui-popover.left-bottom > .webui-arrow {
  top: 50%;
  right: -9px;
  margin-top: -9px;
  border-right-width: 0;
  border-left-color: #adadad;
  border-left-color: rgba(189, 189, 189, 0.8); }
  .webui-popover.left > .webui-arrow:after,
  .webui-popover.left-top > .webui-arrow:after,
  .webui-popover.left-bottom > .webui-arrow:after {
    right: 1px;
    bottom: -8px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff; }

.webui-popover-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1799;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65); }

.webui-popover-primary .webui-popover-title {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5; }

.webui-popover-primary.bottom .webui-arrow {
  border-bottom-color: #3f51b5; }
  .webui-popover-primary.bottom .webui-arrow:after {
    border-bottom-color: #3f51b5; }

.webui-popover-success .webui-popover-title {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50; }

.webui-popover-success.bottom .webui-arrow {
  border-bottom-color: #4caf50; }
  .webui-popover-success.bottom .webui-arrow:after {
    border-bottom-color: #4caf50; }

.webui-popover-info .webui-popover-title {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4; }

.webui-popover-info.bottom .webui-arrow {
  border-bottom-color: #00bcd4; }
  .webui-popover-info.bottom .webui-arrow:after {
    border-bottom-color: #00bcd4; }

.webui-popover-warning .webui-popover-title {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800; }

.webui-popover-warning.bottom .webui-arrow {
  border-bottom-color: #ff9800; }
  .webui-popover-warning.bottom .webui-arrow:after {
    border-bottom-color: #ff9800; }

.webui-popover-danger .webui-popover-title {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336; }

.webui-popover-danger.bottom .webui-arrow {
  border-bottom-color: #f44336; }
  .webui-popover-danger.bottom .webui-arrow:after {
    border-bottom-color: #f44336; }

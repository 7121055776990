.clockpicker-popover {
  z-index: 1200; }
  .clockpicker-popover .btn {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0 0 0.286rem 0.286rem; }
  .clockpicker-popover .btn.active:focus, .clockpicker-popover .btn:active:focus, .clockpicker-popover .btn:focus {
    border: 1px solid transparent;
    outline: dotted thin;
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px; }
  .clockpicker-popover .btn:focus, .clockpicker-popover .btn:hover {
    text-decoration: none; }
  .clockpicker-popover .btn.active, .clockpicker-popover .btn:active {
    background-image: none;
    outline: 0; }
  .clockpicker-popover .btn-default.active, .clockpicker-popover .btn-default:active, .clockpicker-popover .open .dropdown-toggle.btn-default {
    background-image: none;
    border: 1px solid transparent; }
  .clockpicker-popover .btn-block {
    display: block;
    width: 100%; }
  .clockpicker-popover .text-primary {
    color: #3f51b5; }

.clockpicker .input-group-addon {
  cursor: pointer; }

.clockpicker-moving {
  cursor: move; }

.clockpicker-align-left.popover > .arrow {
  left: 25px; }

.clockpicker-align-top.popover > .arrow {
  top: 17px; }

.clockpicker-align-right.popover > .arrow {
  right: 25px;
  left: auto; }

.clockpicker-align-bottom.popover > .arrow {
  top: auto;
  bottom: 6px; }

.clockpicker-popover .popover-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: #757575;
  text-align: center;
  background-color: #fff; }

.clockpicker-popover .popover-title span {
  cursor: pointer; }

.clockpicker-popover .popover-content {
  padding: 12px;
  background-color: #f8f8f8; }

.popover-content:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px; }

.clockpicker-plate {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: visible;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  -webkit-touch-callout: none; }

.clockpicker-canvas, .clockpicker-dial {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 200px;
  height: 200px; }

.clockpicker-minutes {
  visibility: hidden; }

.clockpicker-tick {
  position: absolute;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: #666;
  text-align: center;
  cursor: pointer;
  border-radius: 50%; }

.clockpicker-tick.active, .clockpicker-tick:hover {
  background-color: #7986cb;
  background-color: rgba(0, 149, 221, 0.25); }

.clockpicker-button {
  padding: 10px 0;
  margin: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.clockpicker-button:hover {
  background-color: #f5f5f5;
  background-image: none; }

.clockpicker-button:focus {
  outline: 0 !important; }

.clockpicker-dial {
  transition: opacity 350ms, -webkit-transform 350ms;
  transition: transform 350ms, opacity 350ms;
  transition: transform 350ms, opacity 350ms, -webkit-transform 350ms; }

.clockpicker-dial-out {
  opacity: 0; }

.clockpicker-hours.clockpicker-dial-out {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2); }

.clockpicker-minutes.clockpicker-dial-out {
  -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8); }

.clockpicker-canvas {
  transition: opacity 175ms; }

.clockpicker-canvas-out {
  opacity: .25; }

.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  stroke: none;
  fill: #3f51b5; }

.clockpicker-canvas-bg {
  stroke: none;
  fill: #c5cae9; }

.clockpicker-canvas-bg-trans {
  fill: #7986cb;
  fill: rgba(0, 149, 221, 0.25); }

.clockpicker-canvas line {
  stroke: #3f51b5;
  stroke-width: 1;
  stroke-linecap: round; }

.clockpicker-button.am-button {
  padding: 5px;
  margin: 1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.286rem; }

.clockpicker-button.pm-button {
  padding: 5px;
  margin: 1px 1px 1px 136px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.286rem; }

.has-warning .twitter-typeahead .tt-input, .has-warning .twitter-typeahead .tt-hint {
  border-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .twitter-typeahead .tt-input:focus, .has-warning .twitter-typeahead .tt-hint:focus {
    border-color: #e6e5e5;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px white; }

.has-error .twitter-typeahead .tt-input, .has-error .twitter-typeahead .tt-hint {
  border-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .twitter-typeahead .tt-input:focus, .has-error .twitter-typeahead .tt-hint:focus {
    border-color: #e6e5e5;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px white; }

.has-success .twitter-typeahead .tt-input, .has-success .twitter-typeahead .tt-hint {
  border-color: #fff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .twitter-typeahead .tt-input:focus, .has-success .twitter-typeahead .tt-hint:focus {
    border-color: #e6e5e5;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px white; }

.input-group .twitter-typeahead:first-child .tt-input, .input-group .twitter-typeahead:first-child .tt-hint {
  border-top-left-radius: 0.215rem;
  border-bottom-left-radius: 0.215rem;
  width: 100%; }

.input-group .twitter-typeahead:last-child .tt-input, .input-group .twitter-typeahead:last-child .tt-hint {
  border-top-right-radius: 0.215rem;
  border-bottom-right-radius: 0.215rem;
  width: 100%; }

select .input-group.input-group-sm .twitter-typeahead .tt-input, select .input-group.input-group-sm .twitter-typeahead .tt-hint {
  padding-top: 0;
  padding-bottom: 0; }

.input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-input, .input-group.input-group-sm .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0; }

.input-group.input-group-sm .twitter-typeahead:first-child .tt-input, .input-group.input-group-sm .twitter-typeahead:first-child .tt-hint {
  border-top-left-radius: 0.143rem;
  border-bottom-left-radius: 0.143rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.input-group-sm .twitter-typeahead:last-child .tt-input, .input-group.input-group-sm .twitter-typeahead:last-child .tt-hint {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.143rem;
  border-bottom-right-radius: 0.143rem; }

select .input-group.input-group-lg .twitter-typeahead .tt-input, select .input-group.input-group-lg .twitter-typeahead .tt-hint {
  padding-top: 0;
  padding-bottom: 0; }

.input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-input, .input-group.input-group-lg .twitter-typeahead:not(:first-child):not(:last-child) .tt-hint {
  border-radius: 0; }

.input-group.input-group-lg .twitter-typeahead:first-child .tt-input, .input-group.input-group-lg .twitter-typeahead:first-child .tt-hint {
  border-top-left-radius: 0.286rem;
  border-bottom-left-radius: 0.286rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.input-group-lg .twitter-typeahead:last-child .tt-input, .input-group.input-group-lg .twitter-typeahead:last-child .tt-hint {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.286rem;
  border-bottom-right-radius: 0.286rem; }

.twitter-typeahead {
  float: left;
  width: 100%; }
  .input-group .twitter-typeahead {
    display: table-cell !important; }

.twitter-typeahead .tt-hint {
  color: #616161; }

.twitter-typeahead .tt-input {
  z-index: 2; }
  .twitter-typeahead .tt-input[disabled], .twitter-typeahead .tt-input[readonly], fieldset[disabled] .twitter-typeahead .tt-input {
    cursor: not-allowed;
    background-color: #eee !important; }

.tt-dropdown-menu, .tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1200;
  width: 100%;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 1rem;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid #e0e0e0;
  border-radius: 0.215rem;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05); }
  .tt-dropdown-menu .tt-suggestion, .tt-menu .tt-suggestion {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 300;
    line-height: 1.57142857;
    color: #757575; }
    .tt-dropdown-menu .tt-suggestion.tt-cursor, .tt-dropdown-menu .tt-suggestion:hover, .tt-menu .tt-suggestion.tt-cursor, .tt-menu .tt-suggestion:hover {
      color: #757575;
      text-decoration: none;
      cursor: pointer;
      background-color: #eee;
      outline: 0; }
      .tt-dropdown-menu .tt-suggestion.tt-cursor a, .tt-dropdown-menu .tt-suggestion:hover a, .tt-menu .tt-suggestion.tt-cursor a, .tt-menu .tt-suggestion:hover a {
        color: #757575; }
    .tt-dropdown-menu .tt-suggestion p, .tt-menu .tt-suggestion p {
      margin: 0; }

@charset "UTF-8";
.ms-container:before {
  position: relative;
  top: 68px;
  left: 3.5%;
  display: inline-block;
  font-family: "Material Design Iconic";
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  content: "";
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  text-rendering: auto; }

.ms-container:after {
  display: block;
  height: 0;
  min-height: 0;
  clear: both;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  content: "."; }

.ms-container .ms-selectable, .ms-container .ms-selection {
  float: left;
  width: 45%;
  color: #757575;
  background: #fff; }

.ms-container .ms-selection {
  float: right; }

.ms-container .ms-list {
  position: relative;
  height: 160px;
  padding: 3px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 0.215rem;
  transition: border linear .2s, box-shadow linear .2s; }

.ms-container .ms-list.ms-focus {
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9; }

.ms-container ul {
  padding: 0;
  margin: 0;
  list-style-type: none; }

.ms-container .ms-optgroup-container {
  width: 100%; }

.ms-container .ms-optgroup-label {
  padding: 5px 0 0 7px;
  margin: 0;
  font-weight: 400;
  color: #757575;
  cursor: pointer; }

.ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection {
  padding: 3px 10px 3px 20px; }

.ms-container .ms-selectable li.ms-hover, .ms-container .ms-selection li.ms-hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background-color: #5c6bc0; }

.ms-container .ms-selectable li.disabled, .ms-container .ms-selection li.disabled {
  color: #757575;
  cursor: text;
  background-color: #bdbdbd; }

@media (min-width: 1200px) {
  .ms-container {
    width: 450px; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .ms-container {
    width: 350px; } }

@media (min-width: 480px) and (max-width: 991.98px) {
  .ms-container {
    width: 100%; } }

@media (max-width: 479.98px) {
  .ms-container {
    width: 100%; }
    .ms-container:before {
      top: 190px;
      left: 50%;
      margin-left: -8px; }
    .ms-container .ms-selectable, .ms-container .ms-selection {
      display: block;
      width: 100%; }
    .ms-container .ms-selectable {
      margin-bottom: 40px; } }
